import React, { useEffect, useState } from 'react';

import style from './style.module.scss';

const Promotion = (props: any) => {
  const cta = props?.cta_section;
  const bgImageDesktop = cta?.bg_image_desktop?.data?.attributes?.url;
  const bgImageMobile = cta?.bg_image_mobile?.data?.attributes?.url;
  const bgColor = cta?.bg_color;
  const ctaTitle = cta?.Title;
  const ctaImage = cta?.Background_Image?.data?.attributes?.url;
  const ctaLink = cta?.Link;
  const [bgImage, setBgImage] = useState(bgImageDesktop);

  useEffect(() => {
    const updateBackgroundImage = () => {
      if (window?.innerWidth <= 576) {
        setBgImage(bgImageMobile);
      } else {
        setBgImage(bgImageDesktop);
      }
    };

    updateBackgroundImage();
    window.addEventListener('resize', updateBackgroundImage);

    return () => {
      window.removeEventListener('resize', updateBackgroundImage);
    };
  }, [bgImageDesktop, bgImageMobile]);

  return (
    <div
      className="promotion-banner-general"
      style={{
        background: `${bgImageDesktop ? '' : bgColor || ''}`,
      }}
    >
      {bgImage ? (
        <img
          src={bgImage}
          alt={cta?.bg_image_desktop?.data?.attributes?.alt}
          className={style.promotionBannerImage}
        />
      ) : (
        <div className="d-flex">
          {' '}
          <p>
            {ctaTitle}
            {ctaLink && (
              <a
                className="text-white cursor-pointer ms-2"
                href={ctaLink?.Slug_or_URL}
              >
                – {ctaLink?.Text}
              </a>
            )}
          </p>
          {ctaImage && <img src={ctaImage} alt={ctaTitle} />}
        </div>
      )}
    </div>
  );
};
export default Promotion;
